<template>
  <section :class="{ brand }" class="two-column-text">
    <div class="left">
      <h1 class="display-text">
        {{ displayText }}
      </h1>
    </div>
    <div class="right">
      <h2 class="title">
        {{ title }}
      </h2>
      <div v-if="html" v-html="text" class="text" />
      <p v-else class="text">
        {{ text }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TwoColumnText',
  props: {
    displayText: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    },
    brand: Boolean,
    html: Boolean
  }
}
</script>

<style lang="postcss" scoped>
.two-column-text {
  @apply flex flex-col justify-between items-stretch mx-auto max-w-screen-xl py-72 px-5;
  @apply md:flex-row;
}
.two-column-text.brand {
  @apply bg-brand;
}

.left,
.right {
  @apply flex-1;
}
.left {
  @apply mb-12 md:mb-0 md:pr-11;
}
.right {
  @apply md:pl-11;
}
.display-text {
  @apply text-brand text-5xl font-bold;
}
.brand .display-text {
  @apply text-white;
}
.title,
.text {
  @apply text-4xl;
}
.title {
  @apply text-brand font-bold;
}
.text {
  @apply text-black font-light;
}
.text >>> ul {
  @apply list-disc ml-8;
}
.text >>> li {
  @apply mb-4;
}
.brand .title,
.brand .text {
  @apply text-white;
}
</style>
