<template>
  <main v-if="homeContent" class="home">
    <Hero :image="homeContent.heroImage.url" :text="homeContent.heroTitle" />

    <TwoColumnText
      :displayText="homeContent.leftText"
      :title="homeContent.rightTitle"
      :text="homeContent.rightText"
    />

    <FullImage
      :buttonText="homeContent.linkButtonText"
      :image="homeContent.linkImage.url"
      :buttonLink="homeContent.link"
      :text="homeContent.linkText"
      :title="homeContent.linkTitle"
    />

    <section class="contact">
      <div class="left">
        <h1 class="display-text">
          {{ homeContent.contactText }}
        </h1>
      </div>
      <div class="right">
        <ContactForm />
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Hero from '@/components/layout/Hero'
import TwoColumnText from '@/components/layout/TwoColumnText'
import FullImage from '@/components/layout/FullImage'
import ContactForm from '@/components/ContactForm'

export default {
  name: 'Home',
  title: 'Andinor',
  components: { Hero, FullImage, TwoColumnText, ContactForm },
  computed: {
    ...mapState(['homeContent'])
  },
  created () {
    this.getHomeContent()
  },
  methods: {
    ...mapActions(['getHomeContent'])
  }
}
</script>

<style lang="postcss" scoped>
.contact {
  @apply flex flex-col justify-between items-center mx-auto max-w-screen-xl py-24 px-5;
  @apply md:flex-row;
}
.left,
.right {
  @apply flex-1;
}
.left {
  @apply mb-12 md:mb-0 md:pr-11;
}
.right {
  @apply w-full flex flex-col md:items-center md:pl-11;
}
.display-text {
  @apply text-brand text-5xl font-bold;
}
</style>
